<template>
  <div>
    <div class="row">
<!--      <div class="col-lg-12">-->
<!--        <div class="row">-->
<!--          <div class="col-lg-8 col-sm-8 filters">-->
<!--            <div class="filters__search">-->
<!--              <div class="input-group input-group-sm">-->
<!--                <span class="input-group-addon">-->
<!--                  <i class="fa fa-search"></i>-->
<!--                </span>-->
<!--                <input-->
<!--                  id="search"-->
<!--                  name="search"-->
<!--                  :placeholder="$t('aps.search')"-->
<!--                  class="form-control filters__input"-->
<!--                  v-model="filters.query"-->
<!--                />-->
<!--                <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">-->
<!--                  <i class="fa fa-remove"></i>-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row mb-1">-->
<!--          <div class="col-lg-4 col-md-12">-->
<!--            <div class="input-group input-group-sm filters__location">-->
<!--              <span class="input-group-addon filters__field-icon">-->
<!--                <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>-->
<!--              </span>-->
<!--              <select-->
<!--                class="form-control form-control-sm filters__field"-->
<!--                v-model="filters.company"-->
<!--                @change="changeCompany"-->
<!--              >-->
<!--                <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>-->
<!--                <option v-for="company in companys" :value="company" :key="company.name">-->
<!--                  {{ company.name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div  class="col-lg-4 col-md-12 input-group-for-location-select-in-statreports&#45;&#45;filters" v-if="filters.company === 'All companys'">-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location mr-1">&ndash;&gt;-->
<!--&lt;!&ndash;              <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;              </span>&ndash;&gt;-->
<!--&lt;!&ndash;              <select class="form-control form-control-sm filters__field" v-model="filters.location">&ndash;&gt;-->
<!--&lt;!&ndash;                <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                  {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                </option>&ndash;&gt;-->
<!--&lt;!&ndash;              </select>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--                                                                   <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-statreports&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="refreshReports"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->

<!--          </div>-->
<!--          <div class="col-lg-4 col-md-12" v-else>-->
<!--            <div class="input-group input-group-sm filters__location mr-1">-->
<!--              <span class="input-group-addon filters__field-icon">-->
<!--                <i class="fa fa-map-marker"></i>-->
<!--              </span>-->
<!--              <select @change="refreshReports" class="form-control form-control-sm filters__field" v-model="filters.location">-->
<!--                <option-->
<!--                  v-for="location in locationsObjbyIds(filters.company.locations)"-->
<!--                  :value="location.id"-->
<!--                  :key="location.id"-->
<!--                >-->
<!--                  {{ location.name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              :title="&ndash;&gt;-->
<!--&lt;!&ndash;                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'&ndash;&gt;-->
<!--&lt;!&ndash;                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')&ndash;&gt;-->
<!--&lt;!&ndash;                  : ''&ndash;&gt;-->
<!--&lt;!&ndash;              "&ndash;&gt;-->
<!--&lt;!&ndash;              class="d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="refreshReports"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          &lt;!&ndash;          <div class="col-lg-4 col-md-12">&ndash;&gt;-->
<!--          &lt;!&ndash;            <div class="input-group input-group-sm filters__location ">&ndash;&gt;-->
<!--          &lt;!&ndash;                <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--          &lt;!&ndash;                  <i class="fa fa-building-o"&ndash;&gt;-->
<!--          &lt;!&ndash;                     :class="{ 'text-primary': filters.company !== 'All companys' }"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;                </span>&ndash;&gt;-->
<!--          &lt;!&ndash;              <select&ndash;&gt;-->
<!--          &lt;!&ndash;                class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--          &lt;!&ndash;                v-model="filters.company"&ndash;&gt;-->
<!--          &lt;!&ndash;              >&ndash;&gt;-->
<!--          &lt;!&ndash;                <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}&ndash;&gt;-->
<!--          &lt;!&ndash;                </option>&ndash;&gt;-->
<!--          &lt;!&ndash;                <option v-for="company in companyList" :value="company" :key="company.name">&ndash;&gt;-->
<!--          &lt;!&ndash;                  {{ company.name}}&ndash;&gt;-->
<!--          &lt;!&ndash;                </option>&ndash;&gt;-->
<!--          &lt;!&ndash;              </select>&ndash;&gt;-->
<!--          &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="row mb-1">-->
<!--          <div class="col-12">-->
<!--            <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--              <i class="fa fa-close"></i>-->
<!--              {{ $t('general.resetAllFilters') }}-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="col-lg-12 d-flex justify-content-end mb-h">
        <div class="d-flex justify-content-end mb-1">
          <button class="btn btn-info btn-md" @click="addReport" v-if="!isDisable">{{
            $t('reports.add') }}
          </button>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-sm-12 mb-h filters">
            <div class="">
              <div class="input-group input-group-sm">
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
                <input
                  id="search"
                  name="search"
                  :placeholder="$t('aps.search')"
                  class="form-control filters__input"
                  v-model="filters.query"
                />
                <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">
                  <i class="fa fa-remove"></i>
                </span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-12 mb-h">
            <div class="input-group input-group-sm filters__location">
              <span class="input-group-addon filters__field-icon">
                <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>
              </span>
              <select
                 :disabled="isSelectedDefaultLocationForRequests"
                class="form-control form-control-sm filters__field"
                v-model="filters.company"
                @change="changeCompany"
              >
                <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
                <option v-for="company in companys" :value="company" :key="company.name">
                  {{ company.name }}
                </option>
              </select>
            </div>
          </div>
          <div  class="col-lg-3 col-md-12 mb-h input-group-for-location-select-in-statreports--filters" v-if="filters.company === 'All companys'">
<!--            <div class="input-group input-group-sm filters__location mr-1">-->
<!--              <span class="input-group-addon filters__field-icon">-->
<!--                <i class="fa fa-map-marker"></i>-->
<!--              </span>-->
<!--              <select class="form-control form-control-sm filters__field" v-model="filters.location">-->
<!--                <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>-->
<!--                <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                  {{ location.name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
                                                                   <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-statreports--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                           v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="selectWithChildsLocationInFilters"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>

          </div>
          <div class="col-lg-3 col-md-12 mb-h" v-else>
            <div class="input-group input-group-sm filters__location mr-1">
              <span class="input-group-addon filters__field-icon">
                <i class="fa fa-map-marker"></i>
              </span>
              <select @change="refreshReports" class="form-control form-control-sm filters__field" v-model="filters.location">
                <option
                  v-for="location in locationsObjbyIds(filters.company.locations)"
                  :value="location.id"
                  :key="location.id"
                >
                  {{ location.name }}
                </option>
              </select>
            </div>
          </div>
<!--          <div class="d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--            <div-->
<!--              :title="-->
<!--                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'-->
<!--                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')-->
<!--                  : ''-->
<!--              "-->
<!--              class="d-flex align-items-end justify-content-start"-->
<!--            >-->
<!--              <Switch-component-->
<!--                :disabled="this.filters.location === 'All locations'"-->
<!--                v-model="filters.with_childs"-->
<!--                class="switcher-with-childs-for-use-in-filters"-->
<!--                @change="refreshReports"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--              <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--            </div>-->
<!--          </div>-->
          <!--          <div class="col-lg-4 col-md-12">-->
          <!--            <div class="input-group input-group-sm filters__location ">-->
          <!--                <span class="input-group-addon filters__field-icon">-->
          <!--                  <i class="fa fa-building-o"-->
          <!--                     :class="{ 'text-primary': filters.company !== 'All companys' }"></i>-->
          <!--                </span>-->
          <!--              <select-->
          <!--                class="form-control form-control-sm filters__field"-->
          <!--                v-model="filters.company"-->
          <!--              >-->
          <!--                <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}-->
          <!--                </option>-->
          <!--                <option v-for="company in companyList" :value="company" :key="company.name">-->
          <!--                  {{ company.name}}-->
          <!--                </option>-->
          <!--              </select>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <button v-if="isFiltersActive" class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </button>
          </div>
        </div>
      </div>


    </div>
    <div class="row reports-page">
      <div class="col-lg-12">
<!--        <div class="d-flex justify-content-end mb-1">-->
<!--          <button class="btn btn-warning btn-md" @click="addReport" v-if="!isDisable">{{ $t('reports.add') }}</button>-->
<!--        </div>-->
        <div class="card">
          <!--        <div class="loader loader-backdrop loader-backdrop-statreports" v-if="isReportsLoading"></div>-->
          <div v-if="isReportsLoading"><Loader-spinner /></div>

          <div class="card-header">
            <i class="fa fa-download"></i>
            {{ $t('sidebar.reports') }}
          </div>

          <div class="card-block">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>{{ $t('general.name') }}</th>
                      <th>{{ $t('general.description') }}</th>
                      <th>{{ $t('general.email') }}</th>
                      <th>{{ $t('general.type') }}</th>
                      <th>{{ $t('reports.format') }}</th>
                      <th>{{ $t('reports.collect_period') }}</th>
                      <th>{{ $t('reports.subject') }}</th>
                      <th>{{ $t('general.location') }}</th>
                      <th>{{ $t('general.company') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="report.id" v-for="report in filterItems(reportsList)" @click="editReport(report)">
                      <td>{{ report.name }}</td>
                      <td>{{ report.description }}</td>
                      <td>
                        <span
                          class="text-info tooltip-text ml-q text-nowrap"
                          v-if="report.action_dest.length > 1"
                          v-tooltip.top-center="{
                            content: getAllEmails(report.action_dest)
                          }"
                        >
                          {{ report.action_dest[0] }} +{{ report.action_dest.length - 1 }}
                        </span>
                        <span v-else>{{ report.action_dest[0] }}</span>
                      </td>
                      <!--                    <td>{{ report.type }}</td>-->
                      <!--                    <td>{{ getTypeWithi18n(report.type) }}</td>-->
                      <td>{{ extractReportTypeI18nCaptionForShowInTable(report) }}</td>
                      <td>{{ report.format }}</td>
                      <td>
                        <span
                          class="text-info tooltip-text"
                          v-if="report.collect_period === 'now'"
                          v-tooltip.top-center="{
                            content: `<ul class='list-unstyled text-left m-0'>
                                    <li class='mb-q'><b>${$t('reports.collect_period')}</b></li>
                                    <li><small><b>${$t('reports.tableTooltipStart')}</b>: ${momentFormat(
                              report.timebounds.start
                            )}</small></li>
                                    <li><small><b>${$t('reports.tableTooltipStop')}</b>: ${momentFormat(
                              report.timebounds.stop
                            )}</small></li>
                                 </ul>`,
                            popperOptions: {
                                modifiers: {
                                preventOverflow: {
                                 boundariesElement: 'window'
                                }
                                 }
                             }
                          }"
                        >
                          {{ $t(`reports.period.${report.collect_period}`) }}
                        </span>
                        <span v-else>{{ $t(`reports.period.${report.collect_period}`) }}</span>
                      </td>
                      <!--                    <td>{{ report.subject }}</td>-->
                      <td>{{ getSubjectWithi18n(report.subject) }}</td>
                      <td>
                        <div
                          v-tooltip.top="{ content: locationName(report.base_location) }"
                          class="nowrap-in-statreports-table"
                        >
                          {{ locationName(report.base_location) }}
                        </div>
                      </td>
                      <td>
                        <div
                          v-tooltip.top="{ content: getCompanyName(report.base_location) }"
                          class="nowrap-in-statreports-table"
                        >
                          {{ getCompanyName(report.base_location) }}
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!filterItems(reportsList).length">
                      <td colspan="11" class="no-data-row">
                        <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal class="modal-info" v-model="modalReport" effect="fade/zoom" closeBtn>
        <h4 slot="modal-title" class="modal-title">{{ isNew ? $t('reports.newReport') : $t('reports.editReport') }}</h4>
        <div v-if="updatedReport">
          <div class="form-group mt-1">
            <label for="name">{{ $t('general.name') }}</label>
            <input
              :data-vv-as="$t('general.name')"
              type="text"
              name="name"
              id="name"
              :disabled="isDisable"
              :placeholder="$t('reports.placename')"
              class="form-control"
              v-validate="'required|max:32'"
              :class="{ input: true, 'is-danger': errors.has('name') }"
              v-model="updatedReport.name"
            />
            <span
              v-tooltip.top-center="{ content: errors.first('name') }"
              v-show="errors.has('name')"
              class="help is-danger error_tooltip"
            >
              <i class="fa fa-warning text-error"></i>
            </span>
          </div>

          <div class="form-group mt-1">
            <label for="description">{{ $t('general.description') }}</label>
            <input
              type="text"
              name="description"
              id="description"
              :disabled="isDisable"
              :placeholder="$t('reports.placedescr')"
              class="form-control"
              v-model="updatedReport.description"
            />
          </div>

          <label for="name">{{ $t('general.email') }}</label>
          <div class="input-group">
            <input
              type="text"
              name="uuid"
              class="form-control input input-tag"
              :disabled="isDisable"
              :placeholder="$t('reports.placeHolderAddEmail')"
              @keyup.13="addEmail"
              data-vv-validate-on="input"
              v-validate="'email'"
              v-model="newEmail"
              :class="{ input: true, 'is-danger': errors.has('uuid') }"
            />
            <button
              v-if="!isDisable"
              class="btn btn-success mb-0 button-after-input-field-or-select-field-in-same-string"
              type="button"
              @click="addEmail"
              :disabled="this.newEmail == '' || errors.has('uuid')"
            >
              {{ $t('general.add') }}
            </button>
          </div>
          <div class="mt-1 mb-1">
            <span
              class="uuid"
              @click.prevent="removeEmail"
              :data-index="index"
              v-for="(uuid, index) in updatedReport.action_dest"
              :key="uuid"
            >
              {{ uuid }}
              <i v-if="!isDisable" class="fa fa-remove text-danger"></i>
            </span>
          </div>

          <div class="form-group">
            <label for="collect_period">{{ $t(`reports.collect_period`) }}</label>
            <select
              v-model="updatedReport.collect_period"
              name="collect_period"
              id="collect_period"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option :key="option" v-for="option in collect_period" :value="option">
                {{ $t(`reports.period.${option}`) }}
              </option>
            </select>
          </div>
          <div class="form-group mt-1 d-flex" v-if="updatedReport.collect_period === 'now'">
            <date-picker class="datepicker_width_full" v-if="!isDisable" v-model="updatedReport.timebounds" report />
          </div>

          <div class="input-group" v-else>
            <div class="form-group" v-if="updatedReport.collect_period !== 'now'">
              <label for="hour">{{ $t(`reports.hour`) }}</label>
              <select
                v-model="cronStringDay"
                name="hour"
                id="hour"
                :class="{ 'select-disabled': isDisable }"
                class="form-control"
                :disabled="isDisable"
              >
                <option :key="option" v-for="option in hours">{{ option }}</option>
              </select>
            </div>
            <div class="form-group" v-if="updatedReport.collect_period === 'week'">
              <label for="day_week">{{ $t(`reports.dayWeek`) }}</label>
              <select
                v-model="cronStringWeek"
                name="day_week"
                id="day_week"
                :class="{ 'select-disabled': isDisable }"
                class="form-control"
                :disabled="isDisable"
              >
                <option :key="option" v-for="option in daysWeek" :value="option">
                  {{ $t(`reports.week.${option}`) }}
                </option>
              </select>
            </div>
            <div class="form-group" v-if="updatedReport.collect_period === 'month'">
              <label for="day_month">{{ $t(`reports.dayMonth`) }}</label>
              <select
                v-model="cronStringMonth"
                name="day_month"
                id="day_month"
                :class="{ 'select-disabled': isDisable }"
                class="form-control"
                :disabled="isDisable"
              >
                <option :key="option" v-for="option in daysMonth">{{ option }}</option>
              </select>
            </div>
          </div>
          <!--       объект выгрузки-->
          <div class="form-group" key="subject">
            <label for="format">{{ $t(`reports.subject`) }}</label>
            <select
              v-model="updatedReport['subject']"
              name="subject"
              id="subject"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option :value="option" :key="option" v-for="option in reportOptions.subject">
                {{ $t(`reports.subjects.${option}`) }}
              </option>
            </select>
          </div>
          <!--        тип выгрузки-->
          <div v-if="updatedReport['subject'] !== 'clients'" class="form-group" key="type">
            <label for="type">{{ $t(`reports.type`) }}</label>
            <select
              v-model="updatedReport['type']"
              name="type"
              id="type"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option :value="option" :key="option" v-for="option in reportOptions.type">
                {{ $t(`reports.types.${option}`) }}
              </option>
            </select>
          </div>

          <!--        тип выгрузки для объекта выгрузки Клиенты-->
          <div v-if="updatedReport['subject'] === 'clients'" class="form-group" key="type">
            <label for="type">{{ $t(`reports.type`) }}</label>
            <select
              v-model="updatedReport['type']"
              name="type"
              id="typeForClientsSubject"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option :value="option" :key="option" v-for="option in reportOptions.typeForClientsSubject">
                {{ $t(`reports.typestypeForClientsSubject.${option}`) }}
              </option>
            </select>
          </div>

          <!--        тип отчета-->
          <div class="form-group" key="format">
            <label for="format">{{ $t(`reports.format`) }}</label>
            <select
              v-model="updatedReport['format']"
              name="format"
              id="format"
              :class="{ 'select-disabled': isDisable }"
              class="form-control"
              :disabled="isDisable"
            >
              <option :value="option" :key="option" v-for="option in reportOptions.format">
                {{ $t(`reports.formats.${option}`) }}
              </option>
            </select>
          </div>

          <!--    старая отрисовывалка списков циклом-->
          <!--        <div class="form-group" :key="key" v-for="(options, key) in reportOptions">-->
          <!--          <label :for="key">{{ $t(`reports.${key}`) }}</label>-->
          <!--          <select-->
          <!--            v-model="updatedReport[key]"-->
          <!--            :name="key"-->
          <!--            :id="key"-->
          <!--            :class="{ 'select-disabled': isDisable }"-->
          <!--            class="form-control"-->
          <!--            :disabled="isDisable"-->
          <!--          >-->
          <!--            <option :value="option" :key="option" v-for="option in options">{{ $t(`reports.${key}s.${option}`) }}</option>-->
          <!--          </select>-->
          <!--        </div>-->

          <div class="form-group mt-1 mb-q">
            <Switch-component
              v-model="updatedReport.charts"
              :disabled="isDisable || muteCharts"
              :label="$t('reports.addCharts')"
              id="reports-add-charts"
              key="reports-add-charts"
            />
            <small class="d-block text-muted">{{ $t('reports.addChartsCaption') }}</small>
          </div>
        </div>

        <div class="form-group">
          <label for="company">{{ $t('general.company') }}</label>
          <select
            v-model="updatedReportCompany"
            name="company"
            id="company"
            @change="changeCompanyInModal"
            :class="{ 'select-disabled': isDisable }"
            class="form-control"
            :disabled="isDisable"
          >
            <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
            <option v-for="company in companys" :value="company" :key="company.name">
              {{ company.name }}
            </option>
          </select>
        </div>

        <div class="form-group" v-if="updatedReportCompany === 'All companys'">
          <label for="location">{{ $t('general.location') }}</label>
          <select
            v-model="updatedReport.base_location"
            name="location"
            id="location"
            :class="{ 'select-disabled': isDisable }"
            class="form-control"
            :disabled="isDisable"
          >
            <option v-for="location in locationsItems" :key="location.id" :value="location.id">
              {{ location.name }}
            </option>
          </select>
        </div>
        <div class="form-group" v-else>
          <label for="location">{{ $t('general.location') }}</label>
          <select
            v-model="updatedReport.base_location"
            name="location"
            id="location"
            :class="{ 'select-disabled': isDisable }"
            class="form-control"
            :disabled="isDisable"
          >
            <option
              v-for="location in locationsObjbyIds(updatedReportCompany.locations)"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>

        <!--      <div class="form-group">-->
        <!--        <label for="location">{{ $t('general.location') }}</label>-->
        <!--        <select-->
        <!--          v-model="updatedReport.base_location"-->
        <!--          name="location"-->
        <!--          id="location"-->
        <!--          :class="{ 'select-disabled': isDisable }"-->
        <!--          class="form-control"-->
        <!--          :disabled="isDisable"-->
        <!--        >-->
        <!--          <option v-for="location in locationsItems" :key="location.id" :value="location.id">-->
        <!--            {{ location.name }}-->
        <!--          </option>-->
        <!--        </select>-->
        <!--      </div>-->
        <!--      <div class="form-group">-->
        <!--        <label for="company">{{ $t('general.company') }}</label>-->
        <!--        <select-->
        <!--          v-model="updatedReport.base_location"-->
        <!--          name="company"-->
        <!--          id="company"-->
        <!--          :class="{ 'select-disabled': isDisable }"-->
        <!--          class="form-control"-->
        <!--          :disabled="isDisable"-->
        <!--        >-->
        <!--          <option v-for="company in companyList" :value="company.locationId" :key="company.name">-->
        <!--            {{ company.name}}-->
        <!--          </option>-->
        <!--        </select>-->
        <!--      </div>-->
        <!-- Modal footer -->
        <div slot="modal-footer" class="modal-footer justify-content-between">
          <button
            v-if="!isDisable"
            type="button"
            class="btn btn-info"
            @click="isNew ? createReport(updatedReport) : saveReport(updatedReport)"
            :disabled="muteEdit || errors.any()"
          >
            <span :class="{ invisible: muteEdit }">{{ isNew ? $t('general.create') : $t('general.save') }}</span>
            <span v-if="muteEdit" class="loader loader--mini"></span>
          </button>

          <div>
            <button v-if="!isNew && !isDisable" type="button" class="btn btn-danger mr-1" @click="modalDelete = true">
              {{ $t('general.delete') }}
            </button>
            <button type="button" class="btn btn-secondary" @click="modalReport = false">
              {{ $t('general.close') }}
            </button>
          </div>
        </div>
      </modal>

      <modal title="Delete Report" class="modal-danger" v-model="modalDelete" effect="fade/zoom">
        <h4 slot="modal-title" class="modal-title">{{ $t('reports.deleteReport') }}</h4>
        <span>
          {{ $t('reports.confirmDeleting') }}
          <b>{{ updatedReport.name }}</b>
          ?
        </span>
        <div slot="modal-footer" class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancelDelete">{{ $t('general.cancel') }}</button>
          <button
            type="button"
            class="btn btn-outline-danger"
            @click="deleteReport(updatedReport)"
            :disabled="muteDelete"
          >
            <span :class="{ invisible: muteDelete }">{{ $t('general.delete') }}</span>
            <span v-if="muteDelete" class="loader loader--mini"></span>
          </button>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import reportsService from '../../services/statReportsService';
import DatePicker from '../../components/date-picker.vue';
import Modal from '../../components/Modal.vue';
import locationService from '../../services/locationService';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import helpers from '../../helpers';
import commonService from '../../services/commonService';
import Filters from '../../filters';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';


export default {
  name: 'Statreports',
  components: { Modal, DatePicker, SwitchComponent, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      updatedReportCompany: 'All companys',
      filters: {
        query: '',
        location: 'All locations',
        company: 'All companys',
        with_childs: true
      },
      modalReport: false,
      modalDelete: false,
      updatedReport: false,
      isNew: false,
      // isDate: false,
      newEmail: '',
      cronStringDay: '*',
      cronStringWeek: '*',
      cronStringMonth: '*',
      newReport: {
        action_dest: [],
        action_time: '* * * * *',
        action_type: 'email',
        collect_period: 'now',
        charts: true,
        description: '',
        format: 'xlsx',
        name: '',
        subject: 'cpes_common',
        tags: [],
        timebounds: {
          start: +moment().startOf('day').valueOf(),
          stop: +moment().endOf('day').valueOf()
        },
        type: 'summary',
        base_location: ''
      },
      collect_period: ['now', 'day', 'week', 'month'],
      hours: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23'
      ],
      daysWeek: ['1', '2', '3', '4', '5', '6', '0'],
      daysMonth: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31'
      ],
      reportOptions: {
        type: ['summary', 'current'],
        format: ['csv', 'json', 'xlsx'],
        subject: ['clients', 'events', 'cpes', 'cpes_common'],
        typeForClientsSubject: ['summary', 'current', 'clients', 'clients_auth'] // для клиентов свой набор типов
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    isFiltersActive() {
      return (
        this.filters.query !== '' ||
          this.filters.location !== 'All locations' || this.filters.company !== 'All companys'
      );
    },
    reportsList() {
      return this.$store.state.reportsList;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    muteEdit() {
      return this.$store.state.muteReportEdit;
    },
    muteDelete() {
      return this.$store.state.muteReportDelete;
    },
    isReportsLoading() {
      return this.$store.state.loadingReports;
    },
    muteCharts() {
      return this.updatedReport.subject !== 'cpes_common' || this.updatedReport.format !== 'xlsx';
    },
    locationsItems() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    }
  },
  watch: {
    updatedReport: {
      deep: true,
      handler(val) {
        if (val && (val.subject !== 'cpes_common' || val.format !== 'xlsx')) {
          this.updatedReport.charts = false;
        }
      }
    }
  },
  methods: {
    refreshReports() {
      if (this.filters.location === 'All locations'){
        this.filters.with_childs = true;
      }
      reportsService.getReports(this);
    },
    extractReportTypeI18nCaptionForShowInTable(reportItem) {
      try {
        const reportType = reportItem.type;
        // console.log(reportType)
        if (reportItem.subject === 'clients') {
          return this.getTypeWithi18nFoClientsSubject(reportType);
        }
        return this.getTypeWithi18n(reportType);
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshReports()

    },
    selectWithChildsLocationInFilters() {
      this.$nextTick(() => {
        this.refreshReports()
      })
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    changeCompanyInModal() {
      let firstLocationIdForCompany = '';
      if (this.updatedReportCompany.hasOwnProperty('locations') && this.updatedReportCompany.locations.length > 0) {
        firstLocationIdForCompany = this.updatedReportCompany.locations[0];
        // console.log(firstLocationIdForCompany);
      } else {
        firstLocationIdForCompany = '';
        if (this.$store.state.userData.base_location !== '') {
          firstLocationIdForCompany = this.$store.state.userData.base_location;
        }
      }
      // console.log(firstLocationIdForCompany)
      // this.updatedPage.base_location = firstLocationIdForCompany;
      this.updatedReport.base_location = firstLocationIdForCompany;
    },
    changeCompany() {
      // console.log(val)
      let firstLocationIdForCompany = '';
      if (this.filters.company.hasOwnProperty('locations') && this.filters.company.locations.length > 0) {
        firstLocationIdForCompany = this.filters.company.locations[0];
        // console.log(firstLocationIdForCompany);
      } else {
        // firstLocationIdForCompany = 'All locations';
        firstLocationIdForCompany = this.$store.state.userData.base_location;
          if (firstLocationIdForCompany) {
            this.locationInFiltersData = commonService.locationObjbyId(firstLocationIdForCompany)
          }
      }
      // console.log(firstLocationIdForCompany)
      // this.updatedPage.base_location = firstLocationIdForCompany;
      this.filters.location = firstLocationIdForCompany;
      this.refreshReports();
    },
    locationPartyGetField(id, field) {
      try {
        const showLocationPartyFromId = commonService.showLocationPartyFromLocationId(id);
        return showLocationPartyFromId[field];
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    getCompanyName(locationID) {
      let result = '';
      if (this.locationPartyGetField(locationID, 'name') !== '') {
        result = this.locationPartyGetField(locationID, 'name');
      }
      return result;
    },
    reportsSearchQuery(reportsArray, searchQuery) {
      if (!reportsArray) {
        return [];
      }
      return reportsArray.filter((item) => {
        // поиск по имени  и описанию
        if (
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        // поиск по емейлу
        for (const email of item.action_dest) {
          if (email.toLowerCase().includes(searchQuery.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    },
    // filterItems(items) {
    //   let filteredReports = items;
    //   filteredReports = this.reportsSearchQuery(filteredReports, this.filters.query);
    //   // if (this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys') {
    //   //   this.filters.location = this.filters.location = 'All locations';
    //   //   filteredReports = Filters.locationFilter(filteredReports, this.filters.company.locationId);
    //   // }
    //   filteredReports = Filters.locationFilter(filteredReports, this.filters.location);
    //   return filteredReports;
    // },
    filterItems(items) {
      let filteredReports = items;
      filteredReports = this.reportsSearchQuery(filteredReports, this.filters.query);
      // if (this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys') {
      //   this.filters.location = this.filters.location = 'All locations';
      //   filteredReports = Filters.locationFilter(filteredReports, this.filters.company.locationId);
      // }
      // filteredReports = Filters.locationFilter(filteredReports, this.filters.location);
      return filteredReports;
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters.query = '';
    //   this.filters.location = 'All locations';
    //   this.filters.company = 'All companys';
    //   this.refreshReports();
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters.query = '';
      // this.filters.location = 'All locations';
      this.filters.company = 'All companys';
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();

      this.refreshReports();
    },
    getTypeWithi18n(type) {
      // если находим для такого значения  перевод - возвращем его, если нет возвращаем оригинал
      // console.log('возращаем для остальных типов')
      if (this.$t(`reports.types.${type}`) !== `reports.types.${type}`) {
        return this.$t(`reports.types.${type}`);
      }
      return type;
    },
    getTypeWithi18nFoClientsSubject(type) {
      // если находим для такого значения  перевод - возвращем его, если нет возвращаем оригинал
      // console.log('возращаем для типа клиент')
      if (this.$t(`reports.typestypeForClientsSubject.${type}`) !== `reports.typestypeForClientsSubject.${type}`) {
        return this.$t(`reports.typestypeForClientsSubject.${type}`);
      }
      return type;
    },
    getSubjectWithi18n(subject) {
      // если находим для такого значения  перевод - возвращем его, если нет возвращаем оригинал
      if (this.$t(`reports.subjects.${subject}`) !== `reports.subjects.${subject}`) {
        return this.$t(`reports.subjects.${subject}`);
      }
      return subject;
    },
    addReport() {
      this.errors.clear();
      this.modalReport = true;
      this.isNew = true;
      this.updatedReport = JSON.parse(JSON.stringify(this.newReport));

      const cron = this.updatedReport.action_time.split(' ');
      this.cronStringDay = cron[1] !== '*' ? cron[1] : '0';
      this.cronStringWeek = cron[4] !== '*' ? cron[4] : '1';
      this.cronStringMonth = cron[2] !== '*' ? cron[2] : '1';
    },
    editReport(report) {
      this.errors.clear();
      this.updatedReportCompany = 'All companys';
      this.modalReport = true;
      this.isNew = false;
      this.updatedReport = JSON.parse(JSON.stringify(report));

      if (!this.updatedReport.action_time) {
        this.updatedReport.action_time = this.newReport.action_time;
      }

      const cron = this.updatedReport.action_time.split(' ');
      this.cronStringDay = cron[1] !== '*' ? cron[1] : '0';
      this.cronStringWeek = cron[4] !== '*' ? cron[4] : '1';
      this.cronStringMonth = cron[2] !== '*' ? cron[2] : '1';
    },
    saveReport(report) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          reportsService.updateReport(this, report);
        }
      });
    },
    addEmail() {
      if (!this.newEmail) {
        return;
      }
      this.$validator
        .validateAll({
          email: this.newEmail
        })
        .then((result) => {
          if (result) {
            if (this.updatedReport.action_dest.indexOf(this.newEmail) == -1) {
              this.updatedReport.action_dest.push(this.newEmail);
              this.newEmail = '';
            } else {
              this.newEmail = '';
            }
          }
        });
    },
    removeEmail(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.updatedReport.action_dest.splice(index, 1);
    },
    getAllEmails(emails) {
      let htmlEmails = '';
      emails.forEach((email) => {
        htmlEmails += `<li><small><b></b> ${email}</small></li>`;
      });
      return `<ul class='list-unstyled text-left m-0'>
                                    <li class='mb-q'><b>Emails</b></li>
                                    ${htmlEmails}
                                 </ul>`;
    },
    createReport(report) {
      // reportsService.createReport(this, report);
      this.$validator.validateAll().then((result) => {
        if (result) {
          reportsService.createReport(this, report);
        }
      });
    },
    cancelDelete() {
      this.modalDelete = false;
    },
    deleteReport(report) {
      reportsService.deleteReport(this, report);
    },
    resetData() {
      this.modalReport = false;
      this.modalDelete = false;
      this.isNew = false;
      this.updatedReport = false;
      this.newEmail = '';
      // this.isDate = false;
      this.cronStringDay = '*';
      this.cronStringWeek = '*';
      this.cronStringMonth = '*';
    },
    momentFormat(value) {
      if (value) {
        return moment(value).format(' D MMM YYYY, H:mm ');
      }
    }
  },
  // created() {
  //   this.$store.commit('setCpesList', false);
  //   reportsService.getReports(this);
  //   locationService.getLocations(this);
  //   if (this.$store.state.userData.base_location !== '') {
  //     this.newReport.base_location = this.$store.state.userData.base_location;
  //   }
  // },
  created() {
    this.$store.commit('setCpesList', false);
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      // if (!locationForFiltering || locationForFiltering === '') {
      //   locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
      // }
      //
      // // console.log('locationForFiltering', locationForFiltering)
      // if (locationForFiltering === 'All locations') {
      //   this.locationInFiltersData = [];
      // } else {
      //   if (this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID){
      //     this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      //   }else {
      //     this.locationInFiltersData = commonService.locationObjbyId(locationForFiltering)
      //   }
      //
      // }
              if (!locationForFiltering && locationForFiltering === '') {
          locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
          this.locationInFiltersData = commonService.locationObjbyId(locationForFiltering)
          this.filters.location = locationForFiltering
        } else {
          this.filters.location = locationForFiltering
          this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        }

      // this.filters.location = locationForFiltering
      this.filters.with_childs = withChildsForFiltering;
    }

    reportsService.getReports(this);
    locationService.getLocations(this);
    if (this.$store.state.userData.base_location !== '') {
      this.newReport.base_location = this.$store.state.userData.base_location;
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value).format(' D MMM YYYY, H:mm ');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.nowrap-in-statreports-table {
  max-width: 150px;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}
.datepicker_width_full {
  width: 100%;
}
.form-control {
  min-width: 130px;
}

.loader-backdrop-statreports:after {
  position: absolute;
  top: 30px;
}
</style>

<style>
      .input-group-for-location-select-in-statreports--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-statreports--filters .select__list-container {
    width: 400px;
  }
</style>
